<template>
  <b-table
    :data="data.data"
    :striped="true"
    :narrowed="true"
  >
    <b-table-column
      v-for="(field,index) in data.fields"
      :key="index"
      :field="field.field"
      :label="field.labelShort"
      :numeric="field.type==='numeric'"
    >
      <template v-slot:header="{ column }">
        <b-tooltip
          v-if="column.label!==field.label"
          :label="field.label" append-to-body dashed
        >{{ column.label }}</b-tooltip>
        <span v-else>{{ column.label }}</span>
      </template>
      <template v-slot="props">
        <b-tooltip v-if="props.row[field.field].length > 15">
          <template v-slot:content>{{ props.row[field.field] }}</template>{{ props.row[field.field] | subStr }}
        </b-tooltip>
        <span v-else>
          {{ props.row[field.field] }}
        </span>
      </template>
    </b-table-column>
  </b-table>
</template>

<script>
export default {
  name: 'GeneralInfo',
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  filters: {
    subStr: function (string) {
      return string.substring(0, 15) + '...'
    }
  }
}
</script>

<style>
.table thead th {
  border-bottom: 2px solid;
}
</style>
